<template>
  <list-template ref="listBox" :table-config="tableConfig" :search-config="searchConfig" :table-data="tableData" :total="total" :loading="loading" :current-page="page" @onChangePage="handleCurrentChange" @onHandle="handleClick" @onSearch="onSearch" @onReset="onReset" @onInitiateAppointment="handleAdd">
    <template slot="title">
      <div class="search-tabs">
        <div class="l">
          <div class="item" :class="[create_type === 1 && 'active']" @click="handleChange(1)">预约申请{{ apply ? `(${apply})` : '' }}</div>
          <div class="item" :class="[create_type === 2 && 'active']" @click="handleChange(2)">预约邀请{{ invitation ? `(${invitation})` : '' }}</div>
        </div>
        <Paper :visible.sync="isVisibleDialog" title="问卷详情" :info="info"></Paper>
      </div>
    </template>
  </list-template>
</template>

<script>
import Paper from '../components/Paper'
import { getApi2 } from '@/api'
import { tableListMixin } from '@/utils/mixins'

export default {
	_config:{"route":{"path":"list","meta":{"title":"预约列表","keepAlive":true}}},
  name: 'List',
  mixins: [tableListMixin],
  components: { Paper },
  created() {
    this.getSearchConfig()
    this.getCount()
  },
  activated() {
    this.getCount()
    this.getData()
  },
  methods: {
    onReset() {
      this.search = {}
    },
    getCount() {
      getApi2('/evaluation/appointment/get-list-count').then(res => {
        const { apply, invitation } = res
        this.apply = apply || 0
        this.invitation = invitation || 0
      })
    },
    getSearchConfig() {
      getApi2('/evaluation/result/get-range').then(res => {
        const { grade, class: classList, school } = res
        this.searchConfig[0].options = school
        this.searchConfig[1].options = grade
        this.searchConfig[2].options = classList
      })
      getApi2('/evaluation/appointment/get-param').then(res => {
        const { psychology_status, invited_status, theme } = res
        this.searchConfig[3].options = theme
        this.psychology_status = psychology_status
        this.invited_status = invited_status
        this.searchConfig[4].options = psychology_status
      })
    },
    getData() {
      this.getDataApi('/api2/api/evaluation/appointment/psychology-list', { create_type: this.create_type })
    },
    handleChange(type) {
      this.create_type = type
      this.tableData = []
      this.$store.commit('setPage', 1)
      this.getCount()
      if (type === 1) {
        this.searchConfig[4].options = [...this.psychology_status]
      } else if (type === 2) {
        this.searchConfig[4].options = [...this.invited_status]
      }
      this.$refs.listBox.$children[0].reset()
    },
    handleAdd() {
      this.$router.push('./initiate')
    },
    handleClick(row, handle) {
      const { type, text } = handle
      if (['edit', 'view'].includes(type)) {
        this.$router.push(`./${this.create_type === 1 ? 'detail' : 'initiate'}?id=${row.id}&type=${this.create_type}`)
      } else if(type === 'paper_info') {
        if (text === '无') return
        this.handleShowDialog(row.id)
      }
    },
    handleShowDialog(id) {
      getApi2('/evaluation/sum-up/questionnaire', { id }).then(res => {
        this.info = res
      })
      this.isVisibleDialog = true
    }
  },
  data() {
    return {
      info: {},
      isVisibleDialog: false,
      apply: 0,
      invitation: 0,
      create_type: 1,
      invited_status: [],
      psychology_status: [],
      searchConfig: [
        {
          tag: 'select',
          placeholder: '请选择校区',
          prop: 'school_id',
          options: [],
          label: 'name',
          value: 'id',
          change: (val, search) => {
            this.search = search
            if (search.grade_id) this.search.grade_id = ''
            if (search.class_id) this.search.class_id = ''
            getApi2('/evaluation/result/get-range', { school_id: val }).then(res => {
              const { grade, class: classList } = res
              this.searchConfig[1].options = grade
              this.searchConfig[2].options = classList
            })
          }
        },
        {
          tag: 'select',
          placeholder: '请选择年级',
          prop: 'grade_id',
          options: [],
          label: 'name',
          value: 'id',
          change: (val, search) => {
            this.search = search
            if (search.class_id) this.search.class_id = ''
            const { school_id, grade_id } = search
            getApi2('/evaluation/result/get-range', { school_id, grade_id }).then(res => {
              const { class: classList } = res
              this.searchConfig[2].options = classList
            })
          }
        },
        {
          tag: 'select',
          placeholder: '请选择行政班',
          prop: 'class_id',
          options: [],
          label: 'name',
          value: 'id'
        },
        {
          tag: 'select',
          prop: 'theme_id',
          placeholder: '请选择咨询主题',
          options: [],
          label: 'sub_name',
          value: 'id'
        },
        {
          tag: 'select',
          prop: 'psychology_status',
          placeholder: '请选择状态',
          options: [],
          label: 'name',
          value: 'id'
        },
        {
          tag: 'input',
          prop: 'student_name',
          placeholder: '输入学生姓名'
        },
        {
          tag: 'input',
          prop: 'student_no',
          placeholder: '输入学生学号'
        }
      ],
      tableConfig: [
        {
          type: 'number',
          label: '序号',
          width: '80rem',
          fixed: 'fixed',
        },
        {
          label: '学生姓名',
          width: '100rem',
          fixed: 'fixed',
          render(row) {
            if (row.student_info) {
              const { student_name } = row.student_info
              return student_name
            }
            return ''
          }
        },
        {
          prop: 'student_no',
          label: '学号',
          width: '140rem',
          fixed: 'fixed',
        },
        {
          label: '咨询地点',
          width: '220rem',
          render(row) {
            const { building_name, chamber_name, chamber_no } = row.chamber_info
            if (building_name && chamber_name) {
              return `${chamber_name}（${building_name}${chamber_no}）`
            }
            return ''
          }
        },
        {
          prop: 'time',
          label: '咨询时间',
          width: '200rem'
        },
        {
          label: '咨询主题',
          width: '220rem',
          render(row) {
            const { sub_name } = row.theme_info
            return sub_name
          }
        },
        {
          label: '行政班',
          width: '140rem',
          render(row) {
            const { class_name } = row.student_info
            return class_name
          }
        },
        {
          label: '年级',
          width: '140rem',
          render(row) {
            const { grade } = row.student_info
            return grade
          }
        },
        {
          prop: 'school_info',
          label: '校区',
          width: '180rem'
        },
        {
          prop: 'paper_info',
          label: '问卷详情',
          width: '220rem',
          render(row) {
            return [{ type: 'paper_info', text: row.paper_info || '无' }]
          }
        },
        {
          prop: 'psychology_status',
          label: '咨询状态',
          width: '100rem',
          fixed: 'right',
          render: row => {
            const arr = this.searchConfig[4].options.map(item => item.name)
            arr.unshift('未开始')
            return arr[row.psychology_status]
          }
        },
        {
          label: '操作',
          width: '120rem',
          fixed: 'right',
          handle: true,
          buttons: row => {
            let arr = ['详情', this.create_type === 2 ? '详情' : '处理', '处理', '处理', '详情', '详情', '详情', '详情', '详情']
            return arr[row.psychology_status] === '详情' ? [{ type:"view", text:"详情" }] : [{ type:"edit", text:"处理" }]
          }
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  &-tabs {
    padding-right: 40rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10rem;
    margin-bottom: 20rem;
    .l {
      flex: 1;
      display: flex;
      .item {
        padding: 9rem 15rem;
        font-size: 12rem;
        border-radius: 3rem;
        cursor: pointer;
        &.active {
          color: #fff;
          background-color: #3491fa;
        }
      }
    }
    .r {
      flex-shrink: 0;
      margin-left: 60rem;
    }
  }
}


</style>
